<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="primary--text-kh">{{$t('menu.caseReport')}}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formReport" lazy-validation>
          <v-row>
            <v-col cols="12" md="4">
              <v-select rounded :items="courtList" v-model="selectedCourt" item-text="nameKh" return-object label="តុលាការ" :rules="rule.requiredField()" outlined dense hide-details>
                <template slot="selection" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select @change="onChangePosition" rounded clearable :items="positionList" v-model="selectedPosition" item-value="id" item-text="nameKh" label="ផ្នែកទទួលបន្ទុក" outlined dense hide-details>
                <template slot="selection" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select rounded :items="assignementList" v-model="selectedAssignment" item-value="id" item-text="nameKh" label="អ្នកទទួលបន្ទុក" outlined dense hide-details>
                <template slot="selection" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <span v-if="$t('lang') == 'KH'">
                    {{data.item.nameKh}}
                  </span>
                  <span v-else>{{data.item.nameEn}}</span>
                </template>
              </v-select>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select rounded :items="phaseList" v-model="selectedPhase" item-value="value" return-object label="ដំណាក់កាល" outlined dense hide-details>
                <template slot="selection" slot-scope="data">
                  {{data.item.text}}
                </template>
                <template slot="item" slot-scope="data">
                  {{data.item.text}}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field rounded v-model="reportRequest.startDate" label="កាលបរិច្ឆេទតែងតាំង" outlined dense type="date" hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn rounded class="btn" color="primary" @click="generate">បង្ហាញ</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-3" v-if="selectedReportText != ''">
      <v-card-title class="justify-center">
        <h3 style="font-family: Khmer OS Muol">របាយការណ៍សំណុំរឿង</h3>
      </v-card-title>
      <v-card-subtitle class="mt-1 text-center">
        <h3>{{selectedReportText}}
          <span v-if="selectedPosition && selectedPositionText"> - {{selectedPositionText.nameKh}}</span>
        </h3>
      </v-card-subtitle>
      <v-card-text>
        <v-simple-table class="bordered-table" v-if="reportResult.case != null && reportResult.case.length > 0">
          <tr>
            <th>ល.រ</th>
            <th>តុលាការ</th>
            <th>សរុបរឿងក្តី</th>
            <th>ជនជាប់ចោទសរុប</th>
            <th>ប្រុស</th>
            <th>ស្រី</th>
            <th>អនីតិជន</th>
          </tr>
          <tr style="text-align:center">
            <td>1</td>
            <td>{{selectedReportText}}</td>
            <td>
              <span>{{reportResult.case.length}}</span>
            </td>
            <td>
              <span v-if="reportResult.party && reportResult.party.length > 0">{{reportResult.party.length}}</span>
            </td>
            <td>
              {{reportResult.party.length - countFemale}}
            </td>
            <td>{{countFemale}}</td>
            <td>{{countUnderAge}}</td>
          </tr>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiCalendarAlert,
  mdiPlus,
  mdiAttachment,
  mdiContentSave,
  mdiDelete,
  mdiClose,
  mdiEye,
  mdiEyeCircle,
  mdiFileEdit,
  mdiReceiptTextEdit
} from '@mdi/js'
import SystemService from '@/services/service.system'
import ReportService from '@/services/service.report'
import StaffService from '@/services/service.staff'
import Rule from '@/plugins/rules.js'

export default {
  props: {
    data: Object
  },
  name: 'case-report',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
        mdiContentSave,
        mdiDelete,
        mdiClose,
        mdiEye,
        mdiEyeCircle,
        mdiFileEdit,
        mdiReceiptTextEdit
      },
      rule: '',
      courtList: [],
      selectedCourt: '',
      selectedPositionText: '',
      reportRequest: {},
      reportResult: [],
      positionList: [],
      assignementList: [],
      phaseList: [
        { text: 'អយ្យការ', value: 1 },
        { text: 'ស៊ើបសួរ', value: 2 },
        { text: 'ជំនុំជម្រះ', value: 3 }
      ],
      selectedPosition: '',
      selectedAssignment: '',
      selectedPhase: '',
      selectedReport: '',
      selectedReportText: '',
      selectedDeptText: '',
      reportHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'warrantLetterNo' },
        { text: 'ដីការចេញ', value: 'warrantDate' },
        { text: 'បង្ហាញខ្លួន', value: 'appearanceDate' },
        { text: 'ឈ្មោះអយ្យការ', value: 'prosecutorName' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'កែប្រែ', value: 'actions' }
      ]
    }
  },
  async created() {
    this.rule = Rule

    SystemService.getCourtAll().then(response => {
      this.courtList = response.data
    })

    SystemService.getPositionFromCourt().then(response => {
      this.positionList = response.data
    })
  },
  computed: {
    countFemale() {
      return this.reportResult.party.filter(item => item.gender === 1).length
    },
    countUnderAge() {
      return this.reportResult.party.filter(item => item.age >= 18).length
    }
  },
  methods: {
    async generate(event) {
      if (!this.$refs.formReport.validate()) return

      this.selectedReportText =
        this.selectedCourt != null ? this.selectedCourt.nameKh : ''

      //    alert(this.selectedPosition)
      this.selectedPositionText = this.getSelectedPosition(
        this.selectedPosition
      )

      this.reportRequest.courtId = this.selectedCourt.id
      this.reportRequest.positionId = this.selectedPosition
      this.reportRequest.staffId = this.selectedAssignment

      await ReportService.getCaseReport(this.reportRequest).then(
        response => {
          this.reportResult = response.data
        },
        error => {
          this.reportResult = []
          alert(JSON.stringify(error.response.data))
        }
      )
    },
    async onChangePosition() {
      if (this.selectedPosition === null) return
      await StaffService.getStaffListByPosition(this.selectedPosition).then(
        response => {
          this.assignementList = response.data
        }
      )
    },
    getSelectedPosition(id) {
      return this.positionList.find(function(elem) {
        return elem.id === id
      })
    }
  }
}
</script>